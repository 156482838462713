<template>
  <div class="contas">
    <h1 class="titulo-card fs-4">Meu negócio / Editar empresa</h1>
    <div class="form-group card-principal">
      <div v-if="company" class="row">
        <div class="col-12 form-group">
          <label for="cnpj">CNPJ</label>
          <input v-model="company.legalId" type="text" id="cnpj" class="form-control" placeholder="">
        </div>
        <div class="col-12 form-group">
          <label for="name">Nome fantasia</label>
          <input v-model="company.name" type="text" id="name" class="form-control" placeholder="">
        </div>
        <div class="col-12 form-group">
          <label for="legalName">Razão social</label>
          <input v-model="company.legalName" type="text" id="legalName" class="form-control" placeholder="">
        </div>
        <div class="col-12 form-group">
          <label for="phone">Telefone</label>
          <the-mask v-model="company.phone" type="tel" id="phone" :mask="['(##) ####-####', '(##) #####-####']" placeholder="(DDD) x0000-0000" class="form-control" />
        </div>
        <div class="col-12 form-group">
          <label for="zip">CEP</label>
          <the-mask v-model="company.zip" id="zip" mask="#####-###" :masked="true" class="form-control" />
        </div>
        <div class="col-12 form-group">
          <label for="addressLine1">Endereço (linha 1)</label>
          <input v-model="company.addressLine1" type="text" id="addressLine1" class="form-control" placeholder="">
        </div>
        <div class="col-12 form-group">
          <label for="addressLine2">Endereço (linha 2)</label>
          <input v-model="company.addressLine2" type="text" id="addressLine2" class="form-control" placeholder="">
        </div>
        <div class="col-12 mt-2">
          <button @click="handleSave()" type="button" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']" fixed-width class="me-1"/>
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../modules/api'
import dialogo from '../modules/dialogo'
import loading from '../modules/loading'

export default {
  name: 'ConfigCompany',
  components: {
  },
  data() {
    return {
      company: undefined,
    }
  },
  methods: {
    loadCompany() {
      loading(true);

      api.get(`/config/company`).then(res => {
        loading(false);
        this.company = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
        this.$router.push('/configuracoes/meu-negocio');
      });
    },

    handleSave() {
      loading(true);
      api.put(`/config/company`, this.company).then(() => {
        loading(false);
        this.$router.push('/configuracoes/meu-negocio');
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
  },
  created() {
    this.loadCompany();
  }
}
</script>
